export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'rewardable_type',
    label: '',
    Object: 'value'
  }, {
    prop: 'rewardable_name',
    label: '',
    Object: 'value'
  }, {
    prop: 'reward_product_category',
    label: '',
    Object: 'value'
  }, {
    prop: 'point_price',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value'
  }]
}
